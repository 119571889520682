<template>
  <v-container :key="id">
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3">
          <v-form ref="form" v-model="errors">
            <v-row>
              <v-col cols="12" class="d-flex flex-wrap">
                <v-btn to="/borradores" exact small text>
                  <v-icon left>mdi-arrow-left</v-icon>
                  {{ $t('common.volver') }}</v-btn>
                <v-btn @click.stop="guardar(1)" class="ml-2" small color="secondary">
                  <v-icon left>mdi-content-save</v-icon>
                  {{ $t('common.guardar') }}</v-btn>
                <v-btn @click.stop="guardar(0)" v-if="id" small class="ml-2" color="secondary">
                  <v-icon left>mdi-upload</v-icon>
                  {{ $t('common.subir') }}</v-btn>
                <v-btn :color="errorColor" small class="ml-2" text @click.stop="validate">
                  <v-icon left>mdi-check-all</v-icon>
                  {{ $t('common.validar') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="id" color="error" small class="ml-2" text @click.stop="eliminarBorrador">
                  <v-icon left>mdi-close</v-icon>
                  {{ $t('common.eliminar') }}</v-btn>
              </v-col>
              <v-col cols="12">
                <v-divider class="mt-2" />
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field v-model.trim="borrador.fecha_entrega" outlined dense hide-details="auto"
                  :label="$t('borradores.fecha')" type="date" :rules="[rules.req]" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model.trim="borrador.cups" outlined dense hide-details="auto"
                  :label="$t('borradores.cups')" type="text" @change="getRatio" :rules="[rules.req, rules.cups]"
                  :loading="process['cups']" />
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete :items="[
    { text: 'CAMBIO COMERCIALIZADORA', value: 'CAMBIO' },
    { text: 'NUEVO SUMINISTRO', value: 'NUEVO' },
  ]" :label="$t('borradores.tipo_contrato')" v-model="borrador.tipo_contrato" select outlined dense
                  hide-details='auto' clearable :rules="[rules.req]" @change="getOfertas">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field v-model.trim="borrador.cp_cups" outlined dense hide-details="auto"
                  :label="$t('borradores.cp_cups')" :loading="process['infoCp']" type="text" @change="getInfoCp"
                  :rules="[rules.req]" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2">
                <v-autocomplete v-model="borrador.tarifa" :items="selects.tarifas" :label="$t('borradores.tarifa')"
                  select outlined dense :loading="process['tarifas']" hide-details="auto" clearable
                  @change="updateSelects" :rules="[rules.req]">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field v-model.trim="borrador.consumo" outlined dense hide-details="auto" @change="updateSelects"
                  :label="$t('borradores.consumo')" type="text" />
              </v-col>
              <v-col cols="12" md="1">
                <v-number-input v-model="borrador.potencia1" @change="updateSelects" outlined dense hide-details="auto" v-if="nTerminosPotencia >= 1"
                  :label="$t('borradores.potencia', { p: 1 })" type="text"
                  :rules="[nTerminosPotencia >= 1 ? rules.req : () => 1]" />
              </v-col>
              <v-col cols="12" md="1">
                <v-number-input v-model="borrador.potencia2" @change="updateSelects" outlined dense hide-details="auto" v-if="nTerminosPotencia >= 2"
                  :label="$t('borradores.potencia', { p: 2 })" type="text"
                  :rules="[nTerminosPotencia >= 2 ? rules.req : () => 1]" />
              </v-col>
              <v-col cols="12" md="1">
                <v-number-input v-model="borrador.potencia3" @change="updateSelects" outlined dense hide-details="auto" v-if="nTerminosPotencia >= 3"
                  :label="$t('borradores.potencia', { p: 3 })" type="text"
                  :rules="[nTerminosPotencia >= 3 ? rules.req : () => 1]" />
              </v-col>
              <v-col cols="12" md="1">
                <v-number-input v-model="borrador.potencia4" @change="updateSelects" outlined dense hide-details="auto" v-if="nTerminosPotencia >= 4"
                  :label="$t('borradores.potencia', { p: 4 })" type="text"
                  :rules="[nTerminosPotencia >= 4 ? rules.req : () => 1]" />
              </v-col>
              <v-col cols="12" md="1">
                <v-number-input v-model="borrador.potencia5" @change="updateSelects" outlined dense hide-details="auto" v-if="nTerminosPotencia >= 5"
                  :label="$t('borradores.potencia', { p: 5 })" type="text"
                  :rules="[nTerminosPotencia >= 5 ? rules.req : () => 1]" />
              </v-col>
              <v-col cols="12" md="1">
                <v-number-input v-model="borrador.potencia6" @change="updateSelects" outlined dense hide-details="auto" v-if="nTerminosPotencia >= 6"
                  :label="$t('borradores.potencia', { p: 6 })" type="text"
                  :rules="[nTerminosPotencia >= 6 ? rules.req : () => 1]" />
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete v-model="borrador.reftarifa" :items="selects.ofertas" outlined dense hide-details="auto"
                  :loading="process['ofertas']" :label="$t('borradores.oferta')" @change="updateSelects"
                  :rules="[rules.req]">
                  <template v-slot:prepend>
                    <v-btn icon @click.stop="getOfertas" color="primary"><v-icon>mdi-reload</v-icon></v-btn>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field v-model.trim="intervalo" readonly outlined dense hide-details="auto"
                  :label="$t('borradores.intervalo')" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">
                <v-text-field v-model.trim="borrador.nif_cif_titular" outlined dense hide-details="auto"
                  :label="$t('borradores.dni_titular')" type="text" :rules="[rules.req, rules.dni]" />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model.trim="borrador.cliente" outlined dense hide-details="auto"
                  :label="$t('borradores.nombre_titular')" type="text" :rules="[rules.req]" maxlength="50" />
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field v-model.trim="borrador.dni_firmante" outlined dense hide-details="auto"
                  :label="$t('borradores.dni_firmante')" type="text" :rules="[rules.req, rules.dni]" />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model.trim="borrador.firmante" outlined dense hide-details="auto"
                  :label="$t('borradores.nombre_firmante')" type="text" :rules="[rules.req]" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">
                <v-text-field v-model.trim="borrador.poblacion_cups" outlined dense hide-details="auto"
                  :loading="process['infoCp']" :label="$t('borradores.pob_cups')" type="text" :rules="[rules.req]" />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model.trim="borrador.direccion_punto_suministro" outlined dense hide-details="auto"
                  :label="$t('borradores.dir_cups')" type="text" :rules="[rules.req]" maxlength="50" />
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field v-model.trim="borrador.provincia_cups" outlined dense hide-details="auto"
                  :loading="process['infoCp']" :label="$t('borradores.prov_cups')" type="text" :rules="[rules.req]" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model.trim="borrador.telefono1" outlined dense hide-details="auto"
                  :label="$t('borradores.telf')" type="text" :rules="[rules.req, rules.phone]" />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model.trim="borrador.email" outlined dense hide-details="auto"
                  :label="$t('borradores.email')" type="text" :rules="[rules.req, rules.email]" />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model.trim="borrador.cuenta_bancaria" outlined dense hide-details="auto"
                  :label="$t('borradores.iban')" type="text" :rules="[rules.req, rules.iban]" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="8">
                <v-textarea no-resize auto-grow v-model="borrador.otrosdatos" outlined dense hide-details="auto"
                  label="Otros datos"></v-textarea>
              </v-col>
              <v-col v-if="selects.productos_adicionales && selects.productos_adicionales.length" cols="12" md="4">
                <span class="text-bold">Productos adicionales</span>
                <v-divider />
                <v-checkbox v-for="prod in selects.productos_adicionales" :key="prod.ref"
                  v-model="borrador.productos_adicionales" :value="prod.value" dense hide-details="auto"
                  :label="prod.text" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <FileInput label="Documentación" v-model="borrador.documentacion" :rules="[rules.req]" :multiple="true">
                  <template v-slot:placeholder>
                    <v-subheader>
                      Adjunta aquí la documentación del contrato
                    </v-subheader>
                  </template>
                </FileInput>
              </v-col>
            </v-row>
          </v-form>

          <v-divider class="my-2" />

          <div class="d-flex flex-wrap">
            <v-btn @click.stop="guardar(1)" small color="secondary">
              <v-icon left>mdi-content-save</v-icon>
              {{ $t('common.guardar') }}</v-btn>
            <v-btn @click.stop="guardar(0)" v-if="id" small class="ml-2" color="secondary">
              <v-icon left>mdi-upload</v-icon>
              {{ $t('common.subir') }}</v-btn>
            <v-btn :color="errorColor" small class="ml-2" text @click.stop="validate">
              <v-icon left>mdi-check-all</v-icon>
              {{ $t('common.validar') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="id" color="error" small class="ml-2" text @click.stop="eliminarBorrador">
              <v-icon left>mdi-close</v-icon>
              {{ $t('common.eliminar') }}</v-btn>
          </div>

        </v-card>
      </v-col>


    </v-row>
    <ConfirmDialog ref="confirm" />

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" width="8" color="primary"></v-progress-circular>
    </v-overlay>

  </v-container>
</template>

<script>
import { req, cups, iban, phone, email, nif } from "@/utils/validations.js";
import * as types from "../components/customInputs/index.js";

export default {
  props: {
    id: Number | String,
    confirmEnvio: Boolean,
  },
  components: {
    CustomInput: () => import("../components/CustomInput.vue"),
    FileInput: () => import("../components/customInputs/FileInput.vue"),
    VNumberInput: () => import("../components/customInputs/NumberInput.vue"),
    DocumentacionContrato: () =>
      import("@/components/DocumentacionContrato.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    let today = new Date();
    let day = String(today.getDate()).padStart(2, "0");
    let month = String(today.getMonth() + 1).padStart(2, "0");
    let year = String(today.getFullYear());
    return {
      types,
      schema: {},
      borrador: {
        // tipo_contrato: 'CAMBIO',
        productos_adicionales: [],
        demarcacion: null,
        zonatarifa: null,
        documentacion: [],
        fecha_entrega: `${year}-${month}-${day}`,
      },
      rules: { req, cups, iban, phone, email, nif, dni: nif },
      errors: null,
      loading: false,
      selects: {
        tarifas: [],
        companias: [],
        ofertas: [],
        productos_adicionales: [],
      },
      timeout: null,
      process: {}
    };
  },
  computed: {
    errorColor() {
      switch (this.errors) {
        case true:
          return "error darken-1";
        case false:
          return "success";
        default:
          return "";
      }
    },
    tarifa() {
      return this.selects.tarifas.find((t) => t.value == this.borrador.tarifa);
    },
    oferta() {
      return this.selects.ofertas.find(
        (t) => t.value == this.borrador.reftarifa
      );
    },
    intervalo() {
      return this.oferta?.intervalo;
    },
    tipoContrato() {
      return this.oferta?.TIPO_CONTRATO;
    },
    nTerminosPotencia() {
      switch (this.borrador?.tarifa) {
        case '2.0TD':
          return 2
        case '3.0TD':
        case '6.1TD':
          return 6
        default:
          return 0;
      }
    }
  },
  methods: {
    async eliminarBorrador() {
      if (
        !(await this.$refs.confirm.open(
          "Eliminar borrador",
          "¿Estás seguro de que quieres eliminar este borrador? Esta acción no se puede deshacer",
          { color: "error", confirmColor: "error" }
        ))
      )
        return;

      const { data } = await axios({
        method: "DELETE",
        url: `/borradores/${this.id}`,
      });
      this.$root.$emit("snack", "Eliminado con éxito");
      this.$router.push({ name: "Borradores" });
    },
    async loadData() {
      const { data } = await axios({
        method: "GET",
        url: `/borradores/${this.id}`,
      });

      if (data) {
        // this.borrador = { ...this.borrador, ...data }
        this.$set(this, "borrador", { ...this.borrador, ...data });
      }
    },
    validate() {
      let validacion = this.$refs.form.validate();
      this.errors = !validacion;

      if (validacion) {
        this.$root.$emit('snack', 'Campos validados con éxito', { color: 'success' })
      }

      return validacion;
    },
    async guardar(borrador) {
      if (!borrador && !this.validate()) return;

      if (borrador && !(this.borrador.cups || this.borrador.cliente)) {
        this.$root.$emit(
          "snack",
          "Es obligatorio un CUPS o un titular para guardar el borrador"
        );
        return;
      }

      if (
        !borrador &&
        !(await this.$refs.confirm.open(
          "Enviar a revisión",
          "¿Estás seguro de que quieres enviar este borrador a revisión? Una vez enviado no podrás modificar los datos",
          { color: "primary", confirmColor: "primary" }
        ))
      )
        return;

      this.loading = true;

      axios({
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        url: `/borradores`,
        data: { ...this.borrador, borrador, idBorrador: this.id },
      })
        .then((res) => {
          if (res.data?.redirect == "contratos") {
            this.$root.$emit("snack", "Guardado como contrato");
            this.$router.push({ name: "Contratos" });
            return;
          }
          if (res.data?.refborrador) {
            this.$root.$emit("snack", "Guardado como borrador");
            this.borrador.documentacion = []
            this.$router.push({
              name: "NuevoBorrador",
              query: { id: res.data.refborrador },
            }).finally(() => {
              console.log('RECARGANDO DATOS...')
              this.loadData()
            })
            return;
          }
        })
        .catch((err) => {

          let msg = err?.response?.data?.detail

          this.$root.$emit(
            "snack",
            msg || 'Ha ocurrido un error al guardar el borrador',
            { color: 'error', type: 'error' }
          );
        })
        .finally(() => (this.loading = false));
    },
    async enviar() {
      if (!this.validate()) return;

      this.loading = true;

      axios({
        method: "POST",
        url: `/borradores/${this.id}/enviar_a_revision`,
      })
        .then((res) => {
          this.$root.$emit("snack", "Borrador enviado");
          this.$router.push({ name: "Borradores" });
        })
        .catch((err) => {
          let msg = err?.response?.data?.detail

          this.$root.$emit(
            "snack",
            msg || 'Ha ocurrido un error al enviar el borrador',
            { color: 'error', type: 'error' }
          );
        })
        .finally(() => (this.loading = false));
    },
    async updateSelects() {
      const promises = [this.changeOferta(), this.getTarifas(), this.getOfertas(), this.getProductosAdicionales(),]
      await Promise.all(promises.map(p => p.catch(e => e)));
      // Promise.all([
      //   this.changeOferta(),
      //   this.getTarifas(),
      //   this.getOfertas(),
      //   this.getProductosAdicionales(),
      // ]).catch(() => {});
    },
    async changeOferta() {
      // this.borrador.productos_adicionales = []
      this.getProductosAdicionales();
    },
    async getInfoCp() {
      this.$set(this.process, 'infoCp', true)
      const { data } = await axios({
        url: `/borradores/info_cups`,
        params: { cp: this.borrador.cp_cups },
      });
      this.$set(this.borrador, 'demarcacion', data.demarcacion);
      this.$set(this.borrador, 'provincia_cups', data.provincia);
      this.$set(this.borrador, 'poblacion_cups', data.poblacion);
      this.$set(this.process, 'infoCp', false)
    },
    async getRatio(v) {
      this.$set(this.process, 'ratio', true)
      const { data } = await axios({
        url: `/borradores/alto_ratio`,
        params: { cups: v },
      });

      this.borrador.zonatarifa = data.zona;
      if (data != null) {
        this.$set(this.borrador, "demarcacion", data.demarcacion);
        this.$set(this.borrador, "cp_cups", data.cod_postal);
        this.$set(this.borrador, "potencia1", data.potencia1);
        this.$set(this.borrador, "potencia2", data.potencia2);
        this.$set(this.borrador, "potencia3", data.potencia3);
        this.$set(this.borrador, "potencia4", data.potencia4);
        this.$set(this.borrador, "potencia5", data.potencia5);
        this.$set(this.borrador, "potencia6", data.potencia6);

        this.$set(this.borrador, "tarifa", data.tarifa);
        this.$set(this.borrador, "consumo", data.consumo);
      }
      this.getInfoCp();
      this.getOfertas();
      this.$set(this.process, 'ratio', false)
    },
    async getDemarcaciones() {
      this.$set(this.process, 'demarcaciones', true)
      const { data } = await axios({ url: `/borradores/demarcaciones` });
      this.selects.demarcacion = data;
      this.$set(this.process, 'demarcaciones', false)
    },
    async getCompanias() {
      this.$set(this.process, 'companias', true)
      const { data } = await axios({ url: `/borradores/companias` });
      this.selects.companias = data;
      this.$set(this.process, 'companias', false)
    },
    async getTarifas() {
      this.$set(this.process, 'tarifas', true)
      const { data } = await axios({ url: `/borradores/tarifas` });
      this.selects.tarifas = data;
      this.$set(this.process, 'tarifas', false)
    },
    async getOfertas() {
      this.$set(this.process, 'ofertas', true)
      const { data } = await axios({
        url: `/borradores/ofertas`,
        params: {
          tarifa: this.borrador.tarifa,
          zona: this.borrador.demarcacion,
          ratio: this.borrador.zonatarifa,
          tipo_contrato: this.borrador.tipo_contrato,
          p1: this.borrador.potencia1 || null,
          p2: this.borrador.potencia2 || null,
          p3: this.borrador.potencia3 || null,
          p4: this.borrador.potencia4 || null,
          p5: this.borrador.potencia5 || null,
          p6: this.borrador.potencia6 || null,
        },
      });
      this.selects.ofertas = data;
      this.$set(this.process, 'ofertas', false)
    },
    async getProductosAdicionales() {
      this.$set(this.process, 'productos', true)
      if (this.borrador.reftarifa) {
        const { data } = await axios({
          url: `/borradores/productos_adicionales`,
          params: {
            ratio: this.borrador.zonatarifa,
            demarcacion: this.borrador.demarcacion,
            oferta: this.borrador.reftarifa
          },
        });
        this.selects.productos_adicionales = data;
      } else {
        this.selects.productos_adicionales = []
      }
      this.$set(this.borrador, 'productos_adicionales', this.borrador?.productos_adicionales.filter(p => this.selects.productos_adicionales.some(pp => pp.value == p)))
      // this.borrador.productos_adicionales = []
      this.$set(this.process, 'productos', false)
    },
  },
  async mounted() {
    if (this.id) {
      await this.loadData();
      await this.getOfertas();
      await this.getProductosAdicionales();
    }

    this.getDemarcaciones();
    this.getCompanias();
    this.getTarifas();

    // if (this.id){
    //   this.getOfertas();
    // }

    this.validate()

  },
  watch: {
    "borrador.productos_adicionales"(v) {
      if (v == null) this.borrador.productos_adicionales = [];
    },
    "borrador.potencia1"(v) {
      if (v > 0 && v >= 1000)
        this.$set(this.borrador, "potencia1", this.borrador.potencia1 / 1000);
    },
    "borrador.potencia2"(v) {
      if (v > 0 && v >= 1000)
        this.$set(this.borrador, "potencia2", this.borrador.potencia2 / 1000);
    },
    "borrador.potencia3"(v) {
      if (v > 0 && v >= 1000)
        this.$set(this.borrador, "potencia3", this.borrador.potencia3 / 1000);
    },
    "borrador.potencia4"(v) {
      if (v > 0 && v >= 1000)
        this.$set(this.borrador, "potencia4", this.borrador.potencia4 / 1000);
    },
    "borrador.potencia5"(v) {
      if (v > 0 && v >= 1000)
        this.$set(this.borrador, "potencia5", this.borrador.potencia5 / 1000);
    },
    "borrador.potencia6"(v) {
      if (v > 0 && v >= 1000)
        this.$set(this.borrador, "potencia6", this.borrador.potencia6 / 1000);
    },
  },
};
</script>

<style>
.sticky {
  position: sticky;
  top: 72px;
}
</style>
